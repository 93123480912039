@import '../../../../styles/vars';

.list {
  width: 45%;
  @media (max-width: $tablet-width) {
    width: 100%;
  }
}
.item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 18px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: $tablet-width) {
    font-size: 16px;
  }
}
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  margin-right: 30px;
}
.icon {
  width: 45px;
  max-width: 45px;
  max-height: 35px;
}