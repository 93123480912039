$color-primary: #7e43e7;
$color-primary-rgb: rgb(126, 67, 231);
$color-primary-dark: #704e97;
$color-secondary: #20abff;
$color-secondary-rgb: rgb(32, 171, 255);
$color-accent: #f98300;
$color-black: #000000;
$color-white: #ffffff;
$color-error: #cc1010;
$color-grey-dark: #8c8c8c;
$color-grey-light: #cccccc;
$color-gradient: linear-gradient(
  to bottom right,
  rgba($color-primary, 1),
  rgba($color-secondary, 1)
);

$font-source-sans-pro: "Source Sans Pro";
$font-oswald: "Oswald";

$font-oswald-weight-bold: 500;

$tablet-width: 768px;
$desktop-width: 1000px;
