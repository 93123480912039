@import "../../../../styles/vars";

.contactProcess {
  margin-top: 60px;
  background: $color-gradient;
  color: $color-white;
}
.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0;
  @media (max-width: $tablet-width) {
    display: block;
  }
}
.title {
  text-transform: uppercase;
  font-family: $font-oswald;
  font-size: 40px;
  width: 45%;
  @media (max-width: $tablet-width) {
    width: 100%;
    font-size: 32px;
    margin-bottom: 30px;
  }
}
